import React from 'react'
import "./loading.css"
import img from "../pages/images/smaillogo2.svg"

const Loading = () => {
  return (
    <div className="loading">
        <span className="loader"></span>
       
        
    </div>
   
  )
}

export default Loading
