import React from 'react'
import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'


export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const {t} = useTranslation()
  const servives_data = t("servies" , {returnObjects:true})
  const [services_catgery , setservices_ctgery ] = useState([...servives_data])
  const [massage_Apper , setmassage_Apper] = useState(false) 
const massageHandelar = () => {
  setmassage_Apper(!massage_Apper)

}
const close_massage = () => {
  setmassage_Apper(false)
}
  return (
    <AppContext.Provider value={{ 
    close_massage,
    massage_Apper,
    massageHandelar,
    services_catgery ,
    setservices_ctgery
    }}>
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}